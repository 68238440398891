'use client';
import { useEffect } from 'react';
import { AuthLayout } from 'components/AuthLayout';
import { UnauthLayout } from 'components/UnauthLayout';
import { UnauthHome } from 'components/Home/unauth';
import { AuthHome } from 'components/Home/auth';
import { NavbarMobile } from 'components/Navbar/navbarMobile';
import { UnauthFooter } from 'components/Home/unauthFooter';
import { Sidebar } from 'components/Sidebar';
import { ChatInputBoxAuth } from 'components/ChatInputBox/auth';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';

let already_running = false;

export function Client() {
  const [user] = useAuth();

  useEffect(() => {
    if (already_running) return; // don't re-run too quickly (e.g. double run on start up)
    already_running = true;
    setTimeout(() => {
      already_running = false;
    }, 100);
    analytics.viewedLanding(user);
  }, [user]);

  if (user) {
    return (
      <AuthLayout
        MobileNav={NavbarMobile}
        Left={Sidebar}
        Content={AuthHome}
        Footer={ChatInputBoxAuth}
      />
    );
  } else return <UnauthLayout Content={UnauthHome} Footer={UnauthFooter} />;
}
