'use client';

import { useState } from 'react';
import Image from 'next/image';
import Button from 'components/Button';
import SearchBox from 'components/SearchBox';

import Modal from 'components/Modal';
import RecommendedQs from 'components/recommendedQs';
import {
  defaultRecommendedDev,
  defaultRecommendedProd,
} from 'components/recommendedQs/defaultRecommended';

import { analytics } from 'lib/analytics';

import cleeAIResearchLogo from 'public/images/cleeai-logo-research.svg';

import style from './style.module.css';

export function UnauthHome() {
  const [isUnauthUserModalOpen, setIsUnauthUserModalOpen] = useState(false);
  const defaultRecommended =
    window.location.hostname.substring(0, 4) == 'dev'
      ? defaultRecommendedDev
      : defaultRecommendedProd;

  return (
    <div className={style.unAuthHomeContainer}>
      <div className={style.disableClick}>
        <div className={style.centered}>
          <Image
            className={style.logo}
            src={cleeAIResearchLogo}
            alt="Clee AI logo"
          />
        </div>
        <h1 className={style.heading}>اسأل أي شيء، وثق بكل شيء.</h1>
        <h2 className={style.subHeading}>محرك البحث في الخليج</h2>
        <div className={style.centered}>
          <div
            className={style.searchBoxContainer}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault;
              event.stopPropagation();
              setIsUnauthUserModalOpen(true);
              analytics.clickedHomeSearchboxUnauth();
            }}
          >
            <SearchBox />
          </div>
        </div>
        <div className={style.recommendedQsContainer}>
          <RecommendedQs recommendedQs={defaultRecommended} />
        </div>
      </div>
      <Modal
        isOpen={isUnauthUserModalOpen}
        onCloseCB={() => setIsUnauthUserModalOpen(false)}
      >
        {
          <div className={style.modalBody}>
            <p className={style.subHeadingModal}>
              إنشاء حساب أو تسجيل الدخول للبدء
            </p>
            <div className={style.modalRow}>
              <Button
                href="/create-account"
                className={style.ctaButton}
                variant="primary"
              >
                سجل الآن
              </Button>
              <Button
                href="/login"
                className={style.ctaButton}
                variant="secondary"
              >
                تسجيل الدخول
              </Button>
            </div>
          </div>
        }
      </Modal>
    </div>
  );
}
