'use client';

import SearchBox from 'components/SearchBox';
import RecommendedQs from 'components/recommendedQs';
import {
  defaultRecommendedDev,
  defaultRecommendedProd,
} from 'components/recommendedQs/defaultRecommended';

import style from './style.module.css';

export function AuthHome() {
  const defaultRecommended =
    window.location.hostname.substring(0, 4) == 'dev'
      ? defaultRecommendedDev
      : defaultRecommendedProd;

  return (
    <div className={style.authHomeContainer}>
      <div className={style.authContainerScrollable}>
        <h1 className={style.headingAuth}>اسأل أي شيء، وثق بكل شيء.</h1>
        <div className={style.centeredSearchContainer}>
          <div
            className={style.searchBoxContainer}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault;
              event.stopPropagation();
            }}
          >
            <SearchBox />
          </div>
        </div>
        <div className={style.recommendedQsContainer}>
          <RecommendedQs recommendedQs={defaultRecommended} />
        </div>
      </div>
    </div>
  );
}
