import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Button from 'components/Button';
import style from './style.module.css';
import { Q } from './defaultRecommended';
import { useRouter } from 'next/navigation';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';

interface Props {
  tag: string;
  questions: Q[];
}

export default function QuestionsBox({ tag, questions }: Props) {
  const router = useRouter();
  const [user] = useAuth();

  return (
    <div className={style.questionsBox}>
      <p className={style.subHeadingQA}>{tag || <Skeleton count={1} />}</p>
      {questions.map((q) => (
        <Button
          className={style.questionBtn}
          variant="tertiary"
          key={q.searchId}
          onClickHandler={() => {
            analytics.clickedSuggestedQuery(user, q.searchId, q.question, tag);
            router.push(`/search/${q.searchId}`);
          }}
        >
          {q.question || <Skeleton count={2} />}
        </Button>
      ))}
    </div>
  );
}
