'use client';

import React from 'react';
import Button from 'components/Button';
import style from './style.module.css';
import { useRouter } from 'next/navigation';
import { TagQs } from './defaultRecommended';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';

interface Props {
  recommendedQs: TagQs[];
}

export default function RecommendedQsDesktop({ recommendedQs }: Props) {
  const router = useRouter();
  const [user] = useAuth();
  return (
    <div className={style.qsContainer}>
      {recommendedQs.map((tagQs) => (
        <div className={style.questionsBox} key={tagQs.tag}>
          <p className={style.subHeadingQA}>{tagQs.tag}</p>
          {tagQs.questions.map((q) => (
            <Button
              className={style.questionBtn}
              variant="tertiary"
              key={q.searchId}
              onClickHandler={() => {
                analytics.clickedSuggestedQuery(
                  user,
                  q.searchId,
                  q.question,
                  tagQs.tag,
                );
                router.push(`/search/${q.searchId}`);
              }}
            >
              {q.question}
            </Button>
          ))}
        </div>
      ))}
    </div>
  );
}
