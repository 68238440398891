'use client';

import React, { useRef, useState, useCallback } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import style from './style.module.css';

import chevronLeftIcon from 'public/images/icons/chevronLeft.svg';
import chevronRightIcon from 'public/images/icons/chevronRight.svg';
import { TagQs } from './defaultRecommended';
import QuestionsBox from './questionsBox';

const SCROLL_SIZE_DEFAULT = 350;

interface Props {
  recommendedQs: TagQs[];
  isLoading?: boolean;
}

export default function RecommendedQsMobile({
  recommendedQs,
  isLoading,
}: Props) {
  const searchesContainerRef = useRef<HTMLUListElement>(null);
  const [isLeftControlDisabled, setIsLeftControlDisabled] = useState(false);
  const [isRightControlDisabled, setIsRightControlDisabled] = useState(true);

  const toggleControls = useCallback(() => {
    if (!searchesContainerRef.current) {
      return;
    }
    const searches = searchesContainerRef.current.querySelectorAll('li');

    if (searches instanceof NodeList && Boolean(searches?.length)) {
      const firstSearchDOMRect = searches[0].getBoundingClientRect();
      const lastSearchDOMRect =
        searches[searches.length - 1].getBoundingClientRect();
      const containerDOMRect =
        searchesContainerRef.current.getBoundingClientRect();

      setIsLeftControlDisabled(
        lastSearchDOMRect.left >
          -(containerDOMRect.right - containerDOMRect.left) / 2,
      );
      setIsRightControlDisabled(
        lastSearchDOMRect.right <
          -(containerDOMRect.right - containerDOMRect.left) / 2,
      );
    }
  }, []);

  const handleButtonClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const direction = ev.currentTarget.name;
    const scrollDims = searchesContainerRef?.current?.getBoundingClientRect();
    const SCROLL_SIZE = scrollDims
      ? scrollDims.right - scrollDims.left
      : SCROLL_SIZE_DEFAULT;
    const left = direction === 'right' ? SCROLL_SIZE : -SCROLL_SIZE;

    searchesContainerRef?.current?.scrollBy({
      top: 0,
      left,
      behavior: 'smooth',
    });

    window.setTimeout(toggleControls, 400);
  };

  return (
    <div className={style.scroller}>
      <button
        name="left"
        className={classNames(style.scrollBtn, style.scrollBtnLeft)}
        onClick={handleButtonClick}
        disabled={isLeftControlDisabled}
      >
        <Image
          src={chevronLeftIcon}
          alt="Left chevron icon"
          height={30}
          width={30}
        />
      </button>
      <ul
        ref={searchesContainerRef}
        className={classNames({ [style.disabled]: isLoading })}
      >
        {recommendedQs.map((tagQs) => (
          <li key={tagQs.tag}>
            <QuestionsBox
              tag={tagQs.tag}
              questions={tagQs.questions}
            />
          </li>
        ))}
      </ul>
      <button
        name="right"
        className={classNames(style.scrollBtn, style.scrollBtnRight)}
        onClick={handleButtonClick}
        disabled={isRightControlDisabled}
      >
        <Image
          src={chevronRightIcon}
          alt="Right chevron icon"
          height={30}
          width={30}
        />
      </button>
    </div>
  );
}
